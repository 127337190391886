import axios from 'axios';

const state = {};

const getters = {};

const mutations = {};

const actions = {
  async getAllPrograms(_obj, params) {
    const res = await axios.get(`api/programs/`, { params });
    return res.data;
  },

  async getProgram(_obj, id) {
    const res = await axios.get(`api/programs/${id}/`);
    return res.data;
  },

  async createProgram(_obj, data) {
    return axios.post(`api/programs/`, data);
  },

  async updateProgram(_obj, { id, data }) {
    return axios.patch(`api/programs/${id}/`, data);
  },

  async deleteProgram(_obj, id) {
    return axios.delete(`api/programs/${id}/`);
  },

  async getPublicSchoolPrograms(_obj, params) {
    const res = await axios.get(`api/programs/public-school-programs/`, { params });
    return res.data;
  },
  async getProgramImageUploadPresignedUrl(_obj, data) {
    return (await axios.post(`api/programs/upload-url/`, data)).data.data;
  },
  async updateStatus(_obj, { id, data }) {
    return axios.patch(`api/programs/${id}/update-status/`, data);
  },
  async getSchoolOfferedPrograms(_obj, params) {
    const res = await axios.get(`api/programs/school-offered-programs/`, { params });
    return res.data;
  },
  async getSchoolReferredPrograms(_obj, params) {
    const res = await axios.get(`api/programs/school-referred-programs/`, { params });
    return res.data;
  },
  async getProgramFromSlug(_obj, params) {
    const res = await axios.get(`api/programs/program-from-slug/`, { params });
    return res.data.data;
  },
  async getProgramOfferedClasses(_obj, params) {
    const res = await axios.get(`api/classes/program-offered-classes/`, { params });
    return res.data;
  },
  async getProgramNextClass(_obj, data) {
    const res = await axios.post(`api/classes/program-incoming-class/`, data);
    return res.data;
  },

  async getSchoolProgramAggregate(_obj, params) {
    const res = await axios.get(`api/programs/school-aggregate/`, { params });
    return res.data;
  },
  async getResetOrderPrograms(_obj, params) {
    const res = await axios.get(`api/programs/reset-ordering/`, { params });
    return res.data;
  },

  async getAllProgramFaqs(_obj, params) {
    const res = await axios.get(`api/programs/faqs/`, { params });
    return res.data;
  },

  async createProgramFaq(_obj, data) {
    return axios.post(`api/programs/faqs/`, data);
  },

  async getProgramFaq(_obj, id) {
    const res = await axios.get(`api/programs/faqs/${id}/`);
    return res.data;
  },

  async updateProgramFaq(_obj, { id, data }) {
    return axios.patch(`api/programs/faqs/${id}/`, data);
  },

  async updatePrgFaqsOrder(_obj, { data }) {
    return axios.patch(`api/programs/faqs/update-order/`, data);
  },

  async deleteProgramFaq(_obj, id) {
    return axios.delete(`api/programs/faqs/${id}/`);
  },

  async getPublicPrgFaqs(_obj, params) {
    const res = await axios.get(`api/programs/faqs/public-faqs/`, { params });
    return res.data;
  },
  async getWaitlist(_obj, params) {
    const res = await axios.get(`api/programs/waitlist/list-waitlist/`, { params });
    return res.data;
  },
  async createWaitlist(_obj, data) {
    return axios.post(`api/programs/waitlist/`, data);
  },
  async getAllScholarshipList(_obj, params) {
    const res = await axios.get(`api/programs/scholarships-from-transition/`, { params });
    return res.data;
  },
  async getExtScholarshipList(_obj, params) {
    const res = await axios.get(`api/schools/ext-scholarships-from-transition/`, { params });
    return res.data;
  },
  async createDuplicateProgram(_obj, data) {
    const res = await axios.post(`api/programs/duplicate-program/`, data);
    return res.data;
  },
  async updateProgramOrder(_obj, data) {
    return axios.patch(`api/programs/update-program-order/`, data);
  },
  async getScholarship(_obj, params) {
    const res = await axios.get(`api/programs/scholarships-from-transition/`, { params });
    return res.data;
  },
  async getAllNationalPrograms(_obj, params) {
    const res = await axios.get(`api/programs/national-partners-offered-programs/`, { params });
    return res.data;
  },
};

export const program = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
